import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Footer from '../home/footer'
import Sidebar from '../sidebar'
import './layout.css'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    // {data.site.siteMetadata.title}
    render={data => (
      <>
        <div className="blog-post">
          <div className="header">
            <div className="header-logo">
              <Link to={`/`}>
                <strong>Develop and Go</strong>
                <hr class="brace" />
              </Link>
            </div>
          </div>

          <div className="blog-content">{children}</div>

          <div className="sidebar">
            <Sidebar />
          </div>

          <div className="blog-footer">
            {/* a footer */}
            <Footer />
          </div>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
