import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { slugify } from '../util/utilityFunctions'

const tagsPage = ({ pageContext }) => {
  const { tags, tagPostCounts } = pageContext

  return (
    <Layout pageTitle="All tags">
      <SEO title="All tags" keywords={['tags', 'topics']} />
      <div className="text-center">
        <h1>All tags</h1>
        {tags.map(tag => (
          <Link
            key={tag}
            className="btn btn-primary"
            style={{ width: '90%' }}
            to={`/tags/${slugify(tag)}`}
          >
            {tag}{' '}
            <span className="badge badge-light">{tagPostCounts[tag]}</span>
          </Link>
        ))}
      </div>
    </Layout>
  )
}

export default tagsPage
